import React, { Fragment } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Container, Row, Col, Card, Stack, Table, Image } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Divider, Dropdown, Icon, Label } from "semantic-ui-react";
import axios from 'axios'
import Swal from "sweetalert2";

const OrderDetail = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [order, setOrder] = useState(null)
    const [orderDetail, setOrderDetail] = useState(null)
    const [stateOpt, setStateOpt] = useState([])
    const [status, setStatus] = useState('')
    const [statusCode, setStatusCode] = useState(0)
    const [statusColor, setStatusColor] = useState('gray')
    const [btnChgHide, setBtnChgHide] = useState(false)
    const [dropDsb, setDropDsb] = useState(false)

    const getStateOpt = async (statusBefore) => {
        await axios.get(`${global.SRV}api_rudrunner_cms/controler/order.php/?index=4`)
            .then(res => {
                if (res.data.state === 200) {
                    let data = res.data.data
                    
                    if (//statusBefore === 'CANCEL' || 
                        statusBefore === 'SETTLEMENT' || 
                        statusBefore === 'IN DELIVERY'|| 
                        statusBefore === 'ARRIVE AT DESTINATION' ||
                        statusBefore === 'RETURNED' ||
                        statusBefore === 'WAITING PAYMENT' ||
                        statusBefore === 'PAYMENT PENDING' ){

                        let newData = data.filter(o => o.execute_by !== 's')
                        setStateOpt(newData.map(o => { 
                            return {
                                key: o.id_status,
                                text: o.name.toUpperCase(),
                                value: o.name.toUpperCase(),
                                code: o.id_status,
                                color: o.color,
                            }
                        }))
                    } else {
                        let newData = data.filter(o => o.name.toLowerCase() === statusBefore.toLowerCase())
                        setStateOpt(newData.map(o => { 
                            return {
                                key: o.id_status,
                                text: o.name.toUpperCase(),
                                value: o.name.toUpperCase(),
                                code: o.id_status,
                                color: o.color,
                            }
                        }))
                        setBtnChgHide(true)
                        setDropDsb(true)
                    }
                    // if (statusBefore == "SETTLEMENT") {
                    //     let newData = data.filter(o =>
                    //         o.id_status != 3 &&
                    //         o.id_status != 5 &&
                    //         o.id_status != 4 &&
                    //         o.id_status != 9 &&
                    //         o.id_status != 10 &&
                    //         o.id_status != 11 &&
                    //         o.id_status != 12 &&
                    //         o.id_status != 13 &&
                    //         o.id_status != 14 
    
                    //     )
                    //     setStateOpt(newData.map(o => { 
                    //         return {
                    //             key: o.id_status,
                    //             text: o.name.toUpperCase(),
                    //             value: o.name.toUpperCase(),
                    //             color: o.color,
                    //         }
                    //     }))
                    // } else {
                    //     setStateOpt(data.map(o => {
                    //         return {
                    //             key: o.id_status,
                    //             text: o.name.toUpperCase(),
                    //             value: o.name.toUpperCase(),
                    //             color: o.color
                    //         }
                    //     }))
                    // }
                }
            })
    }

    const handleChangeStatus = (e, {value, options}) => { 
        setStatus(value)
        setStatusCode(options.find(o => o.value === value).code)
        setStatusColor( options.find(o => o.value === value).color)
    }

    const handleUpdateStatus = (status, idOrder) => {
        
        let arr_prod = []
        orderDetail.order_detail.map(p => arr_prod.push({
            new_qty : p.qty,
            id_product : p.id_product,
            barcode : p.barcode,
        }))

        const param = {
            id_order: idOrder,
            status: status,
            products : arr_prod,
            status_code : statusCode,
            id_customer : location.state.id_customer,
            id_cart : location.state.id_cart,
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "By canceling this transaction, the items in the cart will be deleted and stock will return",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3b5998',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios({
                    method: "POST",
                    url: `${global.SRV}api_rudrunner_cms/controler/order.php/?index=5`,
                    headers: { 'Content-Type': 'text/plain' },
                    data: param
                })
                    .then(res => { console.log(res.data)
                        if (res.data.state === 200) {
                            navigate('/order')
                        }
                    })
        
            }
        })
    }

    const getDetail = async (id_order, id_customer) => {
        let param = {
            id_order: id_order,
            id_customer: id_customer
        }

        await axios({
            method: "POST",
            url:`${global.SRV}api_rudrunner_cms/controler/order.php/?index=2`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => {
                console.log(res.data.data)
                if (res.data.state === 200) {
                    setOrderDetail(res.data.data)
                }
            })
    }

    const BtnGenResi = ({ order }) => {
        if (order) {
            // if (!order.customer.no_resi && order.customer.order_state == "IN DELIVERY") {
            //     return (<Button content="Generate Resi" onClick={() => getResiNumber(orderDetail)} />)
            // }

            if (order.customer.no_resi !== null && order.customer.order_state == "IN DELIVERY") {
                return (
                    <Fragment>
                        <label>Nomor Resi :</label>
                        <div>
                            <Label content={order.customer.no_resi} basic size="large" color="orange" icon="check"/>
                        </div>
                    </Fragment>
                )
            }
        }
    }

    const handleClickProduct = (id) => {
        window.open(
            `https://hoopsindonesia.co.id/product/${id}`,
            '_blank' 
          );
    }

    const getResiNumber = async (order) => {
        const param =
        {
            receiver_contact: order.customer.recipient,
            receiver_name: order.customer.first_name + ' ' + order.customer.last_name,
            receiver_addr: order.customer.address_detail,
            receiver_city: order.customer.city,
            receiver_zip: order.customer.zip,
            receiver_region: order.customer.district,
            receiver_phone: order.customer.phone,
            service_code: order.customer.service_display,
            destination_code: order.customer.desti_code,
            destination_description: order.customer.city,
            weight: order.customer.total_weight,
            qty: order.customer.total_qty,
            goods_amount: order.customer.total_qty,
            delivery_price: order.customer.service_cost,
            insurance: "N",
            insurance_amount: 0,
            goods_desc: "Sport Equipment",
            order_number: order.customer.id_order,
        }

        axios({
            method: "POST",
            url: `${global.SRV}api_rudrunner_cms/controler/courier.php/?index=1`,
            headers: { 'Content-Type': 'text/plain' },
            data: param
        })
            .then(res => { console.log(res.data)
                if (res.data.state === 200) {
                    navigate('/order')
                }
            })
    }

    useEffect(() => {
        setOrder(location.state)
        getDetail(location.state.id_order, location.state.id_customer)
        getStateOpt(location.state.order_state)
        setStatus(location.state.order_state)
    }, [location.state])
   
    return (
        <Container fluid>
            <Row className="mt-1 mb-3">
                <div className="text-facebook h3 mb-0">Order Detail <span className="fs-6 text-muted">#{location.state.id_order}</span></div>
                <div className="text-muted">This is order detail that has been created by customer.</div>
            </Row>
            <Divider />
            <Row>
                <Col xl={4} lg={12} md={12}>
                    <Row className="d-flex justify-content-start mb-3 mt-3">
                        <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                            <Card style={{ borderLeft: '5px solid #3b5998' }}>
                                <Card.Body>
                                    <Card.Title>Customer</Card.Title>
                                    <Row className="mt-3 mb-3">
                                        <Col md={12}>
                                            <Stack direction="vertical" gap={2}>
                                                <Label className="fw-bold" icon="user outline" content={orderDetail && orderDetail.customer.customer_name} color="blue" />
                                                <Stack direction="horizontal" gap={1}>
                                                <Label >#{orderDetail && orderDetail.customer.id_customer}</Label>
                                                {orderDetail && (orderDetail.customer.isMember === 'Y') ? <Label content="Member" color="orange"/> : <Label content="Customer"/>}
                                                </Stack>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12} className="mb-3">
                                            <div className="fs-6 text-muted">Email : </div>
                                            <Stack direction="horizontal" gap={2}>
                                                <div className="fs-6 fw-bold">{orderDetail && orderDetail.customer.email}</div>
                                                <div className=""><Label color="blue" size="tiny" content={orderDetail && (orderDetail.customer.verified === '1' ? "Verified" : "Not Verified")} /></div>
                                            </Stack>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <div className="fs-6 text-muted">Verified Date : </div>
                                            <div className="fs-6 fw-bold">{orderDetail && orderDetail.customer.verified_date}</div>
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <div className="fs-6 text-muted">Total spent since registration : </div>
                                            <div className="fs-6 fw-bold">{orderDetail && global.IDR.format(orderDetail.total_spent)}</div>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <div className="h5">Shipping Address : </div>
                                            <div style={{ backgroundColor: 'whitesmoke', height: 250, width: '100%', padding: 10, borderRadius: 4 }}>
                                                <div className="fw-bold">
                                                    <Label color="black" icon="point" className="mb-2" content={orderDetail && orderDetail.customer.address_label} />
                                                    <div className="text-muted">Recipient : {orderDetail && orderDetail.customer.recipient}</div>
                                                    <div>{orderDetail && orderDetail.customer.address_detail} {orderDetail && orderDetail.customer.address_detail_dua}</div>
                                                    <div>{orderDetail && orderDetail.customer.sub_district}</div>
                                                    <div>{orderDetail && orderDetail.customer.district}</div>
                                                    <div>{orderDetail && orderDetail.customer.city}</div>
                                                    <div>{orderDetail && orderDetail.customer.province}</div>
                                                    <div>{orderDetail && orderDetail.customer.zip}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col xl={8} lg={12} md={12}>
                    <Row className="d-flex justify-content-start mb-3 mt-3">
                        <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                            <Card style={{ borderLeft: '5px solid #3b5998' }}>
                                <Card.Body>
                                    <Card.Title>Product ({orderDetail && orderDetail.order_detail.length})</Card.Title>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Item</th>
                                                        <th>Base Price</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        orderDetail &&
                                                        orderDetail.order_detail.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>
                                                                    <Stack direction="horizontal" gap={2}>
                                                                        <Image src={item.image} height={60} />
                                                                        <div>
                                                                            <div className="fw-bold text-primary" style={{cursor:'pointer'}} onClick={() => handleClickProduct(item.id_product)}>{item.product_detail.product_name}</div>
                                                                            <div className="text-muted fw-bold">{item.id_product}</div>
                                                                            <div className="text-muted fw-bold">Size : {item.size}</div>
                                                                        </div>
                                                                    </Stack>
                                                                </td>
                                                                <td className="fw-bold">{global.IDR.format(item.price)}</td>
                                                                <td className="fw-bold">{item.qty}</td>
                                                                <td className="fw-bold">{global.IDR.format(item.total_price)}</td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </Table>
                                            <Row className="d-flex justify-content-center">
                                                <Col md={12} >
                                                    <div style={{ backgroundColor: 'whitesmoke', height: 90, padding: 10, borderRadius: 4 }}>
                                                        <Row className="d-flex align-items-middle fw-bold">
                                                            <Col>
                                                                <div className="text-center">
                                                                    <div className="text-muted">Product</div>
                                                                    <div>{orderDetail && global.IDR.format(orderDetail.customer.total_price)}</div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-center">
                                                                    <div className="text-muted">Voucher</div>
                                                                    <div>{global.IDR.format(orderDetail && orderDetail.customer.total_voucher)}</div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-center">
                                                                    <div className="text-muted">Shipping</div>
                                                                    <div>{orderDetail && global.IDR.format(orderDetail.customer.service_cost)}</div>
                                                                    {orderDetail && orderDetail.customer.shipping_voucher > 0 ? <div style={{color:'coral'}}><Icon name="shipping fast"/> Voucher {orderDetail && orderDetail.customer.shipping_voucher}</div> : <></>}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-center">
                                                                    <div className="text-muted">Shipping Insurance</div>
                                                                    <div>{global.IDR.format(orderDetail && orderDetail.customer.asuransi)}</div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-center">
                                                                    <div className="text-muted">Total Order</div>
                                                                    <div><Label content={orderDetail && global.IDR.format(orderDetail.customer.total_payment)} color="orange"/></div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} className="mb-3 mt-3">
                            <Card style={{ borderLeft: '5px solid #3b5998' }}>
                                <Card.Body>
                                    <Card.Title className="mb-3">Shipping Detail</Card.Title>
                                    <Row className="mb-2">
                                        <Col md={4}>
                                            <Stack direction="horizontal" gap={2}>
                                                <div>
                                                    <div style={{border:'solid 1px lightgrey',borderRadius:5, padding:5}}>
                                                        <Image src={orderDetail && orderDetail.customer.courier_logo} height={36} width={36} />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="fw-bold">{orderDetail && orderDetail.customer.courier_name}</div>
                                                    <div className="text-muted">{orderDetail && orderDetail.customer.courier_description}</div>
                                                </div>
                                            </Stack>
                                        </Col>
                                        <Col className="fw-bold text-center">
                                            <div className="text-muted">Service Type</div>
                                            <div>{orderDetail && orderDetail.customer.service_display}</div>
                                        </Col>
                                        <Col className="fw-bold text-center">
                                            <div className="text-muted">Weight</div>
                                            <div>{orderDetail && orderDetail.customer.total_weight} kg</div>
                                        </Col>
                                        <Col className="fw-bold text-center">
                                            <div className="text-muted">Shipping Cost</div>
                                            <div>{orderDetail && global.IDR.format(orderDetail.customer.service_cost)}</div>
                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12} className="mb-3 mt-3">
                            <Card style={{ borderLeft: '5px solid #3b5998' }}>
                                <Card.Body>
                                    <Card.Title>Status</Card.Title>
                                    <Row className="mt-3">
                                        <Col md={12} className="mb-3">
                                            <Dropdown
                                                className="me-3"
                                                disabled={dropDsb}
                                                button
                                                value={status}
                                                options={stateOpt}
                                                onChange={handleChangeStatus}
                                                style={{ color: statusColor  }}
                                            />
                                            <span className="text-muted me-3">{orderDetail && orderDetail.customer.date_update}</span>
                                            <Button color="facebook" icon="exchange" labelPosition="right" content="Change" hidden={btnChgHide} onClick={() => status != '' ? handleUpdateStatus(status, location.state.id_order) : alert("Status not selected")}/>
                                        </Col>
                                        <Col md={12}><BtnGenResi order={orderDetail} /></Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default OrderDetail